.nav {
    padding-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
  }
.navFont {
      font-weight: bold;
      font-size: 25px;
}

.logo {
      display: flex;
      flex-direction: row;
      justify-content: center;
}

.leftContent {
  display: flex;
  flex-direction: column;
  margin-top: 2%;
  }

.rightContent {
      margin-top: 0;
  }

.List {
    list-style-type: none;
    margin: 0 0 10px;
    color:'#2a3382'
}

li:hover a{
    display: block;
} 
.List .listByStatus {
    padding: 2px 6px;
    display: block;
    border-radius: 4px;
    margin-bottom: 0;
    font-size: 13px;
    color:#2a3382;
    cursor: pointer;
}

.tabStyle {
    list-style-type: none;
    margin: 0;
}
.tabStyle .tab {
    margin-bottom: 10px;
    padding: 10px 0;
    border-bottom: 2px solid #eee;
    position: relative;
}
.tab a {
    display: block;
}
.custom-vote {
    width: 15%;
    text-align: center;
    position: relative;
    min-width: 100px;
    float: left;
}

.btn-vote {
    width: max-content;
    border: 2px solid #999;
    background-color: #fff;
    text-decoration: none;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
}
.btn-vote .vote-count {
    display: block;
    font-size: 20px;
    font-weight: 700;
    line-height: 44px;
    margin-top: 0;
}
.btn-vote .vote-status {
    display: inline-block;
    min-width: 56px;
    max-width: 92px;
    background-color: #999;
    color: #fff;
    font-size: 12px;
    line-height: 18px;
    padding: 2px 2px 0;
    text-transform: uppercase;
}

h3 {
    font-size: 1rem;
    line-height: 1.25rem;
    margin-bottom: .5rem;
    font-weight: 900;
}

.heading-vote {
    margin-bottom: 10px;
    cursor: pointer;
}

.description {
    min-height: 20px;
    font-size: 13px;
    line-height: 140%;
    margin-bottom: 10px;
    word-wrap: break-word;
}
.vote-meta {
    display: flex;
    flex-direction: row;
    font-size: 11px;
    color: #777;
}
.avatar {
    float: left;
    margin-top: 5px;
    padding-left: 15%;
    padding-right: 10px;
}
.vote-meta-created {
    line-height: 16px;
}

.vote-comment-count {
    padding-left: 50%;
    margin-top: 2px;
    font-size: 14px;
}
.search-field{
    width: 200px;
    position: absolute;
    top: 25px;
    right: 10%;
    
}
.iconStyle {
    position: absolute;
    left: 10px;
    top: 7px;
}
.crosStyle {
    position: absolute;
    right: 10px;
    top: 7px;
    cursor: pointer;
    font-size:5;
}
.search-field input {
    border: 1px solid #ddd;
    border-radius: 13px;
    margin: 0;
    padding: 0 25px;
    height: 26px;
    line-height: 26px;
    font-size: 12px;
    box-shadow: blue;
    outline: 0;
    width: 100%;
    color: black;
}

.filteredItem{
    background-color:#2a3382;
    color:'white'
}
.btn{
    color:'#2a3382';
    border:'2px solid #2a3382'
}
.breadcrumb{
    background-color: transparent;
    padding:0px;
    margin: 0px;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open>.dropdown-toggle.btn-primary {
    color: #2a3382;
    background-color:whitesmoke;
    border: 2px solid #2a3382; 
    width:'auto';
    height:'auto'
  }
.nav-item{
    color: #2a3382;
    font-size: medium;

}