.modal-header h1 {
    color: #2a3382;
}

.modal-body h1 {
    color: #2a3382;
}

.modal-body {
    max-height: 700px;
    overflow-y:auto;
}

.linkedAnchors {
    font-size: 1.8em;
    color: #2a3382;
    font-weight: 400;
    text-transform: none;
}

.table-style {
    overflow-x:auto;
}

table {
    display: block !important;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
}

td {
    vertical-align: text-top;
    padding-right: 30px;
}

@media (max-width: 634px) {
    .table-style {
        max-width: 514px;
    }

    table {
        width: 700px;
    }
}

@media (max-width: 417px) {
    .table-style {
        max-width: 314px;
    }
}

@media (max-width: 380px) {
    .table-style {
        max-width: 265px;
    }
}

