.response-image { 
    width: auto;
    padding-bottom: 16px;
    display: inline;
} 

@media (max-width: 749px) {
    .response-image { 
        display: none;
    } 
}
