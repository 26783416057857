.fade-wait-leave {
  opacity: 1;
}
.fade-wait-leave.fade-wait-leave-active {
  opacity: 0;
  transition: opacity 0.4s ease-in;
}

.fade-wait-enter {
  opacity: 0;
}
.fade-wait-enter.fade-wait-enter-active {
  opacity: 1;
  transition: opacity 0.2s ease-in 0.6s;
}

.fade-wait-height {
  transition: height 0.6s ease-in-out;
}

@media print {
  .no-print {
    display: none;
  }
}
.image-author {
  font-size: 18px;
  color: black;
  text-align: center;
  font-weight: bold;
}
body > div:nth-child(21) > div > div.modal.fade.show > div > div {
  width: 750px;
}
.tag-cloud {
  flex: 1;
}

.tag-cloud > div {
  transition: 1.4s;
}
.app-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 1000px;
  width: 100%;
}
.app-outer {
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  padding: 20px 0;
  position: absolute;
  right: 0;
  top: 0;
}
.modal-height {
  height: 100vh;
}
.modal-body {
  max-height: 700px;
  overflow-y: auto;
}
body > div:nth-child(21) > div > div.modal.fade.show > div > div {
  width: 550px;
}