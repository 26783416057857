.back-link {
    position: relative;
    font-size: 12px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: #505050;
}

.back-link::after {
    content: "";
    left: -15px;
    top: 4px;
    position: absolute;
    border: solid 4px black;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom-color: transparent;  
}