@media print {
    .no-print {
        display: none;
    }

    .styles_closeButton__20ID4 {
        display: none;
    }

    .styles_overlay__CLSq- {
        padding-top: 0;
    }

    .styles_modal__gNwvD {
        top: 0;
        margin-top: 0;
        vertical-align: 0;
    }
}
