.box-div{
    height: 250px;
    background-image: url(../../assets/payment.jpg);
    background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
    border-radius: 5px;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.box-div-black{
    background: rgba(0, 0, 0, 0.3);
    width: 450px;
    height: 250px;
    padding-top: 40px;
    border-radius: 5px;
}
.box-price{
    color: white;
    margin-top: 20px;
    font-weight: bold;
}
.box-btn{
    margin-top: 30px;
    max-width: max-content;   
}