#email-recipient-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.8);
    z-index: 999;
}

.em-modal-header {
    text-align: right;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
}

.em-modal-header .close-btn {
    background: white;
    border: none;
    font-size: 30px;
    height: 40px;
    width: 40px;
}

.em-modal-body-wrap {
    height: 100%;
    width: 100%;
    top: 0;
    position: relative;
    padding-top: 100px;
}

.em-remove-item {
    background: none;
    border: none;
    margin-left: 10px;
    width: 20px;
    height: 20px;
    font-size: 17px;
    line-height: 20px;
}

.em-modal-body {
    background: white;
    max-width: 100%;
    padding: 45px 30px;
    width: 700px;
    margin: auto;
    position: relative;
}

.em-modal-body .large-input {
    width: 100%;
    height: 50px;
    outline: none;
    padding: 0 10px;
    font-size: 14px;
}

.email-list {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 25px;
}

.email-list li {
    padding: 5px 10px 5px 15px;
    background: #eee;
    display: inline-block;
    border-radius: 20px;
    font-size: 15px;
    margin-right: 5px;
    margin-bottom: 5px;
}

.send-email-btn {
    margin-top: 15px;
}

.plus-btn {
    font-size: 30px!important;
}
