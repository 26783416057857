.custom-fancy-box {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 15px;
    background: rgba(0,0,0,0.9);
    z-index: 999;
}

.fancy-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    background: rgba(0,0,0,0.5);
}

.fancy-close-btn {
    background: rgba(0,0,0,0.5);
    border: none;
    color: white;
    font-size: 30px;
    width: 45px;
    height: 45px;
}

.fancy-image-subcontainer {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.fancy-image-container {
    width: 100%;
    height: 100%;
    position: relative;
    display: table;
}
.fancy-image-container img {
    max-width: 100%;
    max-height: calc(100vh - 100px);
}
.fancy-action {
    position: absolute;
    top: 50%;
    z-index: 2;
}
.fancy-prev-btn {
    left: 0;
}
.fancy-next-btn {
    right: 0;
}
.next-btn,
.prev-btn {
    background: rgba(0,0,0,0.5);
    color: white;
    border: none;
    font-size: 30px;
    width: 70px;
    height: 70px;
    padding-bottom: 10px;
    line-height: 60px;
    outline: none;
}

.fancy-counter {
    position: absolute;
    background: rgba(0,0,0,0.5);
    top: 0;
    left: 0;
    height: 45px;
    padding: 0 20px;
    line-height: 45px;
    z-index: 2;
    font-size: 15px;
}
.fancy-checkbox {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    margin: 0;
}
.fancy-image-wrapper {
    position: relative;
    display: inline-block;
}
