.nav {
    padding-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
  }
.navFont {
      font-weight: bold;
      font-size: 25px;
}

.logo {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
}

.leftContent {
  display: flex;
  flex-direction: column;
  margin-top: 2%;
  }

.rightContent {
      margin-top: 0;
  }

.List {
    list-style-type: none;
    margin: 0 0 10px;
}

.List .listByStatus {
    padding: 2px 6px;
    display: block;
    border-radius: 4px;
    margin-bottom: 0;
    font-size: 13px;
    color: #2A6DB6;
    cursor: pointer;
}

h3 {
    font-size: 1rem;
    line-height: 1.25rem;
    margin-bottom: .5rem;
    font-weight: 900;
}








.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open>.dropdown-toggle.btn-primary {
  color: #2a3382;
  background-color:whitesmoke;
  border: 2px solid #2a3382; 
  width:'auto';
  height:'auto'
}
