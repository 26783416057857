body > div:nth-child(20) > div > div.modal.fade.show > div > div {
  width: 600px;
}
.modal-body-content {
  padding: 20px;
}
.row {
  display: flex;
}
.img-center {
  justify-content: center;
}
h6 {
  color: black;
  font-weight: bold;
}
.para {
  font-size: 14px;
  color: black;
}
.para2 {
  font-size: 14px;
  color: black;
  font-weight: 400;
}
.modal-body {
  max-height: 700px;
  overflow-y: auto;
}
body > div:nth-child(21) > div > div.modal.fade.show > div > div {
  width: 550px;
}
