.large-input.group-input {
    width: 100%;
    height: 50px;
    outline: none;
    padding: 0 10px;
    border: 1px solid;
    border-radius: 5px;
    font-size: 14px;
    margin-bottom: 15px;
}

.mosaic-creator .selection-dropdown {
    padding: 15px 0;
    position: absolute;
    top: 100%;
    background: white;
    border: 1px solid rgba(0,0,0,.125);
    width: calc(100% - 30px);
    z-index: 1;
}

.selection-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.selection-list li:hover {
    background: #eeeeee;
}

.selection-list li {
    font-size: 15px;
    padding: 3px 15px;
}

.mosaic-wrapper {
    padding: 90px 0;
}

.mc-group-list {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 20px;
}

.mc-group-list li {
    padding: 5px 10px 5px 15px;
    background: #eee;
    display: inline-block;
    border-radius: 20px;
    font-size: 15px;
    margin-right: 5px;
    margin-bottom: 5px;
}

.generate-btn {
    padding: 0 30px;
    display: inline-block!important;
    margin-right: 10px;
    margin-bottom: 15px;
}

.mosaic-creator .plus-btn {
    margin-bottom: 15px;
}

.card-header-wrap {
    padding: 30px 100px 0 100px;
}

@media (max-width: 575px) {
    .generate-btn {
        width: 100%!important;
    }
    .card-header-wrap {
        padding: 10px 20px 0 20px;
    }
}

.mc-default-msg {
    font-size: 15px;
    width: 100%;
    text-align: center;
    display: block;
}
.mosaic-gallery{
    margin: 0px
}