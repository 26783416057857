h5 {
  width: 100%;
}
.html-content {
  font-size: 16px;
  padding: 2rem;
  color: black;
}
.intro {
  font-size: 16px;
  color: #2a3382;
  width: 100%;
  text-align: center;
  margin-top: 10px;
  cursor: pointer;
}
